import axios from 'axios';
import $auth from "./auth";

const {
    VUE_APP_API_URL
// eslint-disable-next-line no-undef
} = process.env;

const VUE_APP_EUPROTEGIDO_SERVICE_URL = VUE_APP_API_URL + "/euprotegido/"


const findByCPFGroupingByFinancialGroup = (cpf) => {
    const url = `${VUE_APP_EUPROTEGIDO_SERVICE_URL}`  + 'connect_company/findByCPFGroupingByFinancialGroup/' + cpf;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const findByPhysicalPersonIdGroupingByFinancialGroup = (physicalPersonId) => {
    const url = `${VUE_APP_EUPROTEGIDO_SERVICE_URL}`  + 'connect_company/findByPhysicalPersonIdGroupingByFinancialGroup/' + physicalPersonId;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const findAllInvitationsByUserEmail = (email) => {
    const url = `${VUE_APP_EUPROTEGIDO_SERVICE_URL}`  + 'connect_company_invitation/findAllByUserToInviteEmail';

    return axios({
        url: url,
        method: 'post',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
        data : {
            email
        }
    });
};

const existsInvitationByUserEmail = (email) => {
    const url = `${VUE_APP_EUPROTEGIDO_SERVICE_URL}`  + 'connect_company_invitation/existsByUserToInviteEmail' ;

    return axios({
        url: url,
        method: 'post',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
        data : {
            email
        }
    });
};


const requestConnectionWithCompany = (data) => {
    const url = `${VUE_APP_EUPROTEGIDO_SERVICE_URL}` + 'connect_company_request/requestConnectionWithCompany';

    return axios({
        url: url,
        method: 'post',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
        data,
    });
};

const disconnectFromCompany = (connectCompanyID) => {
    const url = `${VUE_APP_EUPROTEGIDO_SERVICE_URL}`  + 'connect_company_connection/disconnectFromCompany/' + connectCompanyID;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const invitationToConnectionWithCompany = (data) => {
    const url = `${VUE_APP_EUPROTEGIDO_SERVICE_URL}` + 'connect_company_invitation/invitationToConnectionWithCompany';

    return axios({
        url: url,
        method: 'post',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
        data,
    });
};

const acceptConnectionWithCompany = (connectCompanyID, userID) => {
    const url = `${VUE_APP_EUPROTEGIDO_SERVICE_URL}`  + 'connect_company_invitation/acceptConnectionWithCompany/' + connectCompanyID + '/' +  userID;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const getScreeningHistory = (page,  size= 10) => {
    const url = `${VUE_APP_EUPROTEGIDO_SERVICE_URL}screening/screening_history?page=${page}&size=${size}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const refuseConnectionWithCompany = (connectCompanyID, userID) => {
    const url = `${VUE_APP_EUPROTEGIDO_SERVICE_URL}`  + 'connect_company_invitation/refuseConnectionWithCompany/' + connectCompanyID + '/' +  userID;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const loadContractsByConnectCompanyConnectionUserId = (userID) => {
    const url = `${VUE_APP_EUPROTEGIDO_SERVICE_URL}`  + 'connect_company_connection/loadContractsByConnectCompanyConnectionUserId/' +  userID;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};
export {
    findByCPFGroupingByFinancialGroup,
    findByPhysicalPersonIdGroupingByFinancialGroup,
    findAllInvitationsByUserEmail,
    requestConnectionWithCompany,
    disconnectFromCompany,
    invitationToConnectionWithCompany,
    existsInvitationByUserEmail,
    acceptConnectionWithCompany,
    refuseConnectionWithCompany,
    loadContractsByConnectCompanyConnectionUserId,
    getScreeningHistory
};
