/* eslint-disable */
import HttpService from '../HttpService';
import {findByPhysicalPersonIdGroupingByFinancialGroup} from "@/common/euprotegido-service";
export default class EuProtegidoService {
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`euprotegido`);
  }

  SaveScreening(data) {
    return this._httpService.post("/screening/save_screening_survey", data);
  }

  SaveMedicalRelease(data) {
    return this._httpService.post("/screening/medical_release", data);
  }

  GetScreeningHistory() {
    return this._httpService.get("/screening/screening_history");
  }

  GetTemplateAboutApp(version){
    return this._httpService.get('/about/get_template_about_app',version);
  }

  async GetCovidConfigurations() {
    return await this._httpService.get("/screening/covid_configuration");
  }

  GetScreeningSurveyIdByFinancialGroup(){
    return this._httpService.get('/screening/getScreeningSurveyIdByFinancialGroup');
  }

  GetKnowledgeTrailSurveyIdByFinancialGroup(){
    return this._httpService.get('/covid/getKnowledgeTrailSurveyIdByFinancialGroup');
  }

  async GetCovidTotalizers(){
    return await this._httpService.get('/covid/get_covid_totalizers');
  }

  FindById(id) {
    return this._httpService.get("/screening/" + id);
  }

  FindConsolidatedProtectionItensByUserId() {
    return this._httpService.get('/consolidated_protection/findConsolidatedProtectionItensByUserId');
  }

  FindCompanyNameConfigurationByFinancialGroup(){
    return this._httpService.get('/connect_company/findCompanyNameConfigurationByFinancialGroup');
  }

  async FindCompanyBenefitOrder(){
    return await this._httpService.get('/connect_company/findCompanyBenefitOrder');
  }

  async FindByPhysicalPersonIdGroupingByFinancialGroup(physicalPersonId) {
    return await this._httpService.get('/connect_company/findByPhysicalPersonIdGroupingByFinancialGroup/' + physicalPersonId);
  }
}

